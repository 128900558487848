import styled from "styled-components";
import { css } from "styled-components";

export const HeroMessageContainer = styled.div`
  width: 70%;
  padding-top: 60px;

  @media (max-width: 800px) {
    width: 70%;
  }
  @media (max-width: 600px) {
    width: 100%;
  }
`;

export const HeroTitle = styled.h1`
  font-size: 46px;
  padding: 0;
  margin: 0;
  font-weight: 700;
  max-width: 800px;
  line-height: 1.4em;
  @media (max-width: 900px) {
    font-size: 40px;
  }
  @media (max-width: 600px) {
    font-size: 32px;
  }
  @media (max-width: 500px) {
    font-size: 24px;
  }
`;

export const HeroSubtitle = styled.h2`
  font-weight: 400;
  letter-spacing: 0.02em;
  position: relative;
  font-size: 14px;
  line-height: 2em;
  white-space: pre-line;
  width: 100%;
`;

export const Hero = styled.div<{ small?: boolean; secondary?: boolean }>`
  position: relative;
  color: ${({ theme }) => theme.textColorLight};
  height: 760px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  @media (max-width: 750px) {
    height: 600px;
  }
  ${({ secondary }) =>
    secondary &&
    css`
      height: 600px;
      @media (max-width: 750px) {
        height: 450px;
      }
    `};
  ${({ small }) =>
    small &&
    css`
      height: 400px;
      @media (max-width: 750px) {
        height: 400px;
      }
    `};
`;

export const HeroImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
`;

export const HeroImageContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  z-index: -1;
  width: 100%;
  overflow: hidden;
  &::after {
    content: " ";
    background-color: #000;
    opacity: 0.35;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

export const Separator = styled.hr<{ light?: boolean }>`
  background-color: ${({ theme, light }) =>
    light ? theme.textColorLight : theme.mainColor};
  width: 140px;
  margin: 25px 0 20px 0;
  height: 2px;
  border: 0;
`;

export const SectionTitle = styled.h2`
  font-size: 14px;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  position: relative;
  width: 100%;
  padding-bottom: 20px;
  color: ${({ theme }) => theme.mainColor};
`;

export const SectionInfo = styled.p`
  font-family: ${({ theme }) => theme.fonts.title};
  font-weight: 400;
  color: ${({ theme }) => theme.mainColor};
  font-size: 46px;
  padding: 0;
  margin: 0;
  font-weight: 700;
  line-height: 1.4em;
  max-width: 800px;
  @media (max-width: 900px) {
    font-size: 40px;
  }
  @media (max-width: 600px) {
    font-size: 32px;
  }
  @media (max-width: 500px) {
    font-size: 24px;
  }
`;

export const SectionDescription = styled.p`
  font-size: 14px;
  padding: 0;
  margin: 0;
  font-weight: 400;
  line-height: 1.8em;
  color: ${({ theme }) => theme.textColor};
  @media (max-width: 700px) {
    font-size: 14px;
    margin-bottom: 30px;
  }
`;

export const Flexbox = styled.div`
  display: flex;
  @media (max-width: 750px) {
    flex-direction: column;
  }
`;

export const SectionHeading = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: ${({ theme }) => theme.mainColor};
`;

export const Section = styled.section`
  width: 100%;
  position: relative;
  padding: 80px 0;
`;

export const Email = styled.a`
  font-size: 14px;
  padding-top: 24px;
  font-weight: 700;
  letter-spacing: 0.04em;
  display: inline-block;
  text-decoration: none;
  position: relative;
  transition: all 300ms;
  color: ${({ theme }) => theme.textColor};
  &::before {
    background: ${({ theme }) => theme.textColor};
    opacity: 0;
    bottom: -4px;
    content: "";
    height: 2px;
    left: 50%;
    position: absolute;
    width: 0%;
    transition: all 300ms;
    transform: translateX(-50%) translateY(0);
  }
  &:hover {
    &::before {
      opacity: 1;
      width: 100%;
    }
  }
`;

export const Phone = styled.a`
  font-size: 14px;
  padding-top: 24px;
  font-weight: 700;
  letter-spacing: 0.04em;
  display: inline-block;
  text-decoration: none;
  position: relative;
  transition: all 300ms;
  color: ${({ theme }) => theme.textColor};
  &::before {
    background: ${({ theme }) => theme.textColor};
    opacity: 0;
    bottom: -4px;
    content: "";
    height: 2px;
    left: 50%;
    position: absolute;
    width: 0%;
    transition: all 300ms;
    transform: translateX(-50%) translateY(0);
  }
  &:hover {
    &::before {
      opacity: 1;
      width: 100%;
    }
  }
`;
