import AOS from "aos";
import "aos/dist/aos.css";
import Footer from "components/Footer";
import Header from "components/Header";
import { graphql, StaticQuery } from "gatsby";
import "normalize.css";
import React from "react";
import Helmet from "react-helmet";
import { addLocaleData, IntlProvider } from "react-intl";
import it from "react-intl/locale-data/it";
import defaultTheme from "themes/default.json";
import itMessages from "translations/locales/it.json";
import { PageLocale } from "types";
import { createGlobalStyle, ThemeProvider } from "styled-components";
import simplePathJoin from "utils/simplePathJoin";
import { addTrailingSlash } from "utils/url";
import defaultImage from "../images/studio-dsg-og.png";

interface Props {
  theme: any;
  isPostPage: boolean;
  hideHeader?: boolean;
  title: string;
  description: string;
  image?: string | null;
  localeCode: string;
  defaultLocaleCode: string;
  pageLocales?: PageLocale[];
}

addLocaleData([...it]);

const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css?family=Noto+Serif:400,700');
  * {
    margin: 0;
    padding: 0;
  }

  body {
    font-family: ${props => props.theme.fonts.text};
    font-display: swap;
  }
  h1 {
    font-family: 'Noto Serif', serif;
  }
  p {
    margin: 0;
  }
  a[x-apple-data-detectors] {
    color: inherit !important;
    text-decoration: none !important;
    font-size: inherit !important;
    font-family: inherit !important;
    font-weight: inherit !important;
    line-height: inherit !important;
  }

  a[href^="tel"] {
    color: inherit;
    text-decoration: none;
    color: #333;
  }
  #iubenda-cs-banner {
    bottom: 0px !important;
    left: 0px !important;
    position: fixed !important;
    width: 100% !important;
    z-index: 99999998 !important;
    background-color: black;
    font-family: ${({ theme }) => theme.fonts.text};;
    border-top: 1px solid #ccc;
  }
  .iubenda-cs-content {
    display: block;
    margin: 0 auto;
    padding: 20px;
    width: auto;
    font-size: 14px;
    background: #fff;
    color: #333;}
  .iubenda-cs-rationale {
    max-width: 900px;
    position: relative;
    margin: 0 auto;
  }
  .iubenda-banner-content > p {
    line-height: 1.5;
  }
  .iubenda-cs-close-btn {
    margin:0;
    color: #000;
    text-decoration: none;
    font-size: 14px;
    position: absolute;
    top: 0;
    right: 0;
    border: none;
  }
  .iubenda-cs-cookie-policy-lnk {
    text-decoration: underline;
    color: #333;
    font-size: 14px;
    font-weight: 900;
  }

`;

interface ILayoutPageQueryData {
  site: {
    siteMetadata: {
      origin: string;
    };
  };
}

class Page extends React.Component<Props> {
  static defaultProps = {
    theme: defaultTheme,
    defaultLocaleCode: "it",
    isPostPage: false
  };

  componentDidMount() {
    AOS.init({
      duration: 500
    });
  }

  render() {
    const {
      children,
      theme,
      isPostPage,
      hideHeader,
      localeCode,
      defaultLocaleCode,
      pageLocales,
      title,
      image,
      description
    } = this.props;

    const translations: { [code: string]: any } = { it: itMessages };
    const currentPageLocale = pageLocales
      ? pageLocales.find(pageLocale => pageLocale.code === localeCode)
      : undefined;
    if (pageLocales && !currentPageLocale) {
      throw new Error(`Couldn't find page with locale code ${localeCode}`);
    }

    return (
      <IntlProvider locale={localeCode} messages={translations[localeCode]}>
        <ThemeProvider theme={theme}>
          <StaticQuery
            query={graphql`
              query LayoutPageQuery {
                site {
                  siteMetadata {
                    origin
                  }
                }
              }
            `}
          >
            {(data: ILayoutPageQueryData) => {
              return (
                <>
                  <GlobalStyle />
                  <Helmet>
                    <html lang={localeCode} />
                    <meta name="viewport" content="width=device-width" />
                    {currentPageLocale && (
                      <link
                        rel="canonical"
                        href={`https://studiodsg.it${addTrailingSlash(
                          currentPageLocale!.url
                        )}`}
                      />
                    )}
                    <title>{`${title}`}</title>
                    <meta
                      name="description"
                      content={
                        description ||
                        "Studio DSG, al Tuo fianco per la crescita della Tua impresa."
                      }
                    />
                    <meta
                      name="keywords"
                      content="Studio DSG, Studio Dalla Stella Gatti, commercialisti, consulenze contabili, revisori contabili, aziende, privati"
                    />
                    <meta property="og:title" content={title} />
                    <meta property="og:type" content="website" />
                    {currentPageLocale && (
                      <meta
                        property="og:url"
                        content={simplePathJoin(
                          data.site.siteMetadata.origin,
                          currentPageLocale.url
                        )}
                      />
                    )}
                    <meta
                      property="og:description"
                      content={
                        description ||
                        "Studio DSG, al Tuo fianco per la crescita della Tua impresa."
                      }
                    />

                    <meta property="og:image" content={image || defaultImage} />
                  </Helmet>
                  {!hideHeader && (
                    <Header
                      locale={localeCode}
                      defaultLocale={defaultLocaleCode}
                      pageLocales={pageLocales}
                      isPostPage={isPostPage}
                    />
                  )}
                  {children}
                  <Footer />
                </>
              );
            }}
          </StaticQuery>
        </ThemeProvider>
      </IntlProvider>
    );
  }
}

export default Page;
