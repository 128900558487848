import React, { SVGProps } from "react";
import styled from "styled-components";

class Logo extends React.PureComponent<SVGProps<SVGSVGElement>> {
  render() {
    return (
      <svg
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 460 200"
        className={this.props.className}
      >
        <g>
          <g>
            <path
              className="st0"
              d="M0,158.1h7.1c0.76,7.41,7.54,14.48,18.14,14.48c12.02,0,18.03-7.62,18.03-14.7
				c0-8.17-7.32-12.09-14.75-13.94l-10.16-2.4c-15.52-3.7-17.81-12.74-17.81-18.73c0-12.41,11.47-20.47,23.93-20.47
				c12.78,0,22.95,7.84,23.38,20.36h-7.21c-0.44-8.28-7.32-13.61-16.39-13.61c-8.52,0-16.5,5.34-16.5,13.72
				c0,3.48,1.09,9.47,12.35,12.09l10.16,2.4c11.91,2.94,20.22,9.37,20.22,20.26c0,10.56-8.74,21.78-25.24,21.78
				C10.49,179.34,0.55,168.99,0,158.1z"
            />
            <path
              className="st0"
              d="M79.88,133.38H68.19v44.87H61.3v-44.87h-9.29v-5.99h9.29v-20.26h6.88v20.26h11.69V133.38z"
            />
            <path
              className="st0"
              d="M129.05,127.39v50.86h-6.99v-8.93c-3.06,7.19-11.36,10.02-17.7,10.02c-12.35,0-20.76-8.28-20.65-22.32
				v-29.62h6.99v29.29c0,9.8,6.12,15.79,14.42,15.79c8.09,0,16.83-4.57,16.94-16.88v-28.2H129.05z"
            />
            <path
              className="st0"
              d="M189.92,98.75v79.5h-6.99v-9.91c-3.82,6.97-11.8,11-20.33,11c-14.21,0-25.9-11.11-25.9-26.57
				c0-15.46,11.58-26.46,25.9-26.46c8.63,0,16.5,4.03,20.33,10.89V98.75H189.92z M182.93,152.98c0-12.31-9.62-20.04-19.78-20.04
				c-10.71,0-19.45,8.49-19.45,19.71c0,11.33,8.74,20.04,19.45,20.04C173.75,172.69,182.93,164.74,182.93,152.98z"
            />
            <path
              className="st0"
              d="M199.21,108.66c0-2.94,2.51-5.01,5.35-5.01c3.06,0,5.46,2.07,5.46,5.01c0,2.83-2.4,4.9-5.46,4.9
				C201.72,113.56,199.21,111.49,199.21,108.66z M201.07,127.39h6.99v50.86h-6.99V127.39z"
            />
            <path
              className="st0"
              d="M215.71,152.66c0-16.12,12.13-26.35,26.66-26.35s26.77,10.24,26.77,26.35c0,16.12-12.24,26.68-26.77,26.68
				S215.71,168.77,215.71,152.66z M262.15,152.66c0-11.98-9.07-19.71-19.78-19.71c-10.82,0-19.67,7.73-19.67,19.71
				c0,12.09,8.85,20.04,19.67,20.04C253.08,172.69,262.15,164.74,262.15,152.66z"
            />
          </g>
          <g>
            <path
              className="st1"
              d="M382.71,65.64h13.52c0.27,7.79,7.55,15.32,18.95,15.32c10.87,0,17.23-6.6,17.23-14.13
				c0-8.05-7.29-11.88-14.71-13.86l-11.66-2.91c-19.48-4.89-22.66-16.37-22.66-23.9c0-15.98,14.18-26.15,30.34-26.15
				c16.43,0,29.28,10.3,29.28,26.81h-13.52c0-8.58-6.76-14.39-16.17-14.39c-8.61,0-16.3,5.15-16.3,13.47
				c0,2.91,0.93,8.98,12.45,11.89l11.53,2.91c15.37,3.83,25.04,12.15,25.04,25.49c0,15.19-12.46,27.2-30.87,27.2
				C395.7,93.37,382.71,80.3,382.71,65.64z"
            />
            <path
              className="st1"
              d="M459.88,137.08L459.88,137.08l-36.52,0v11.15h22.46c-0.04,0.44-0.12,0.87-0.19,1.24
				c-1.65,10.63-10.91,17.49-23.6,17.49c-15.08,0-27.34-12.83-27.34-28.61c0-16.11,12.01-28.72,27.34-28.72
				c6.71,0,13.43,2.66,18.45,7.31l0.41,0.37l9.05-8.27l-0.45-0.44c-7.19-7.04-16.94-10.92-27.45-10.92
				c-22.56,0-40.24,17.86-40.24,40.67c0,22.74,17.67,40.55,40.24,40.55c12.36,0,21.91-3.76,28.32-10.93v22.4h-157.8V9.7h62.41v31.35
				c-3.99-6.8-12.35-10.13-20.85-10.13c-16.21,0-29.72,12.05-29.72,31.16c0,18.98,13.51,31.29,29.85,31.29
				c8.11,0,16.73-3.46,20.72-10.26v8.98h12.61V9.7V0.01V0H282.9v200H460v-62.92H459.88z M335.66,81.45
				c-10.29,0-18.66-8.34-18.66-19.49c0-11.28,8.36-19.11,18.66-19.11c9.26,0,19.17,7.05,19.17,19.11
				C354.83,74.01,345.83,81.45,335.66,81.45z"
            />
            <path
              className="st0"
              d="M373.75,172.02l-13.9-12.34c0.94-1.77,4.75-9.91,4.75-25.31v-0.49h-0.49h-5.66h-0.49v0.49
				c0,8.44-0.91,13.81-1.68,16.82c-0.54,2.13-1.08,3.41-1.4,4.06l-20.95-18.66c11.15-9.23,15.37-13.54,15.37-21.34
				c0-4.66-1.91-9.02-5.38-12.28c-3.72-3.49-8.78-5.34-14.63-5.34c-5.85,0-10.91,1.85-14.63,5.34c-3.47,3.26-5.38,7.62-5.38,12.28
				c0,8.04,5.71,13.21,14.36,20.97l-0.21,0.16c-13.14,10.09-19.14,14.69-19.14,23.52c0,5.07,2.35,9.74,6.62,13.15
				c4.57,3.65,10.93,5.58,18.38,5.58c10.87,0,21.64-6.65,26.9-13.36l13.24,11.8l0.38,0.34l0.33-0.39l3.66-4.31l0.31-0.37
				L373.75,172.02z M329.31,132.4c-0.87-0.78-1.82-1.56-2.82-2.39c-4.85-4.03-10.35-8.59-10.35-14.75c0-6.37,5.53-11,13.14-11
				c7.62,0,13.14,4.63,13.14,11C342.43,121.92,338.32,125.26,329.31,132.4z M311.26,158.8c0-5.26,5.99-9.84,13.58-15.64
				c1.15-0.88,2.33-1.78,3.54-2.72l22.97,20.53c-4.3,5.56-12.69,11.05-22.06,11.05C318,172.01,311.26,167.07,311.26,158.8z"
            />
          </g>
        </g>
      </svg>
    );
  }
}

export default styled(Logo)`
  .st0 {
    fill: #d1b8a4;
  }
  .st1 {
    fill: #fff;
  }
`;
