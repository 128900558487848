import Logo from "components/Logo";
import Wrapper from "components/Wrapper";
import { graphql, StaticQuery } from "gatsby";
import React from "react";
import { FormattedMessage } from "react-intl";
import { useLocation, useWindowScroll } from "react-use";
import ThemeInterface from "themes/theme";
import { PageLocale } from "types";
import styled, { css, withTheme } from "styled-components";
import MenuIcon from "./MenuIcon";
import { Link } from "components/Link";

interface Props {
  theme: ThemeInterface;
  locale: string;
  defaultLocale: string;
  pageLocales?: PageLocale[];
  isPostPage: boolean;
}

interface HeaderQueryData {
  navigation: {
    locales: NavigationLocale[];
  };
}

interface NavigationLocale {
  language: string;
  main: {
    links: Array<{ label: string; url: string }>;
  };
}

function Header({
  locale = "it",
  defaultLocale = "it",
  pageLocales,
  theme,
  isPostPage
}: Props) {
  const [isSideBarOpen, setSideBarOpen] = React.useState(false);
  const { y } = useWindowScroll();
  const [isFixedBarVisible, setFixedBarVisible] = React.useState(y > 200);
  const state = useLocation();

  React.useEffect(
    () => {
      setFixedBarVisible(y > 200);
    },
    [y]
  );

  React.useEffect(
    () => {
      setFixedBarVisible(false);
    },
    [state]
  );

  return (
    <StaticQuery
      query={graphql`
        query HeaderQuery {
          navigation: settingsJson(fields: { name: { eq: "navigation" } }) {
            locales {
              language
              main {
                links {
                  label
                  url
                }
              }
            }
          }
        }
      `}
      render={({ navigation }: HeaderQueryData) => {
        const localizedNavigation = navigation.locales.find(
          navLocale => navLocale.language === locale
        );
        if (!localizedNavigation) {
          throw new Error(`Didn't found any navigation with locale ${locale}`);
        }
        return (
          <DesktopMenuContainer>
            <FixedBar isVisible={isFixedBarVisible}>
              <Wrapper>
                <LogoLink to="/" isPostPage={isPostPage} className="fixed">
                  <Logo />

                  <AssistiveText>
                    <FormattedMessage
                      id="header.logo.assistiveText"
                      defaultMessage="Link to home page"
                    />
                  </AssistiveText>
                </LogoLink>
                <RightBarItems className="fixed">
                  <List>
                    {localizedNavigation.main &&
                      localizedNavigation.main.links.map(link => (
                        <ListItem key={link.label}>
                          <NavLink
                            className="fixed"
                            to={link.url}
                            isPostPage={isPostPage}
                          >
                            {link.label}
                          </NavLink>
                        </ListItem>
                      ))}
                  </List>
                  <NavBarMenu
                    className="fixed"
                    isPostPage={true}
                    onClick={() => setSideBarOpen(true)}
                  />
                </RightBarItems>
              </Wrapper>
            </FixedBar>
            <Wrapper>
              <LogoLink to="/" isPostPage={isPostPage}>
                <Logo />

                <AssistiveText>
                  <FormattedMessage
                    id="header.logo.assistiveText"
                    defaultMessage="Link to home page"
                  />
                </AssistiveText>
              </LogoLink>
              <RightBarItems>
                <List>
                  {localizedNavigation.main &&
                    localizedNavigation.main.links.map(link => (
                      <ListItem key={link.label}>
                        <NavLink to={link.url} isPostPage={isPostPage}>
                          {link.label}
                        </NavLink>
                      </ListItem>
                    ))}
                </List>
                <NavBarMenu
                  isPostPage={isPostPage}
                  onClick={() => setSideBarOpen(true)}
                />
              </RightBarItems>
            </Wrapper>
            <>
              <SideBarContainer isOpen={isSideBarOpen}>
                <SideBarLinks>
                  {localizedNavigation.main &&
                    localizedNavigation.main.links.map(link => (
                      <SideBarLink key={link.label}>
                        <Link to={link.url}>{link.label}</Link>
                      </SideBarLink>
                    ))}
                </SideBarLinks>
              </SideBarContainer>
              {isSideBarOpen && <Mask onClick={() => setSideBarOpen(false)} />}
            </>
          </DesktopMenuContainer>
        );
      }}
    />
  );
}

export const Mask = styled.div`
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 10000;
  background-color: #000;
  opacity: 0.3;
  transition: 0.3s;
`;

const SideBarContainer = styled.div<{ isOpen: boolean }>`
  box-shadow: 6px 0 15px rgba(36, 37, 38, 0.18);
  background-color: #000;
  z-index: 1000;
  transition: transform 0.3s ease-in-out;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 10002;
  height: 100%;
  transform: translate(100%);
  overflow-y: scroll;
  height: 100vh;
  width: 300px;
  background-color: #fff;
  ${({ isOpen }) =>
    isOpen &&
    css`
      transform: translate(0);
    `};
`;

const SideBarLinks = styled.ul`
  padding: 0;
  margin: 0;
  padding: 70px 30px;
  list-style: none;
`;
const SideBarLink = styled.li`
  text-decoration: none;
  padding: 5px 0;
  margin-bottom: 15px;
  a {
    text-decoration: none;
    color: ${({ theme }) => theme.mainColor};
    font-weight: 700;
    text-transform: uppercase;
    font-size: 14px;
    letter-spacing: 0.1em;
  }
`;

const NavBarMenu = styled(({ isPostPage, ...rest }) => <MenuIcon {...rest} />)<{
  isPostPage: boolean;
}>`
  color: ${({ theme }) => theme.textColorLight};
  display: none;
  width: 24px;
  height: 24px;
  stroke: #fff;
  ${({ isPostPage }) =>
    isPostPage &&
    css`
      stroke: #333;
    `};
  @media (max-width: 850px) {
    display: block;
  }
`;
const AssistiveText = styled.span`
  height: 1px;
  width: 1px;
  position: absolute;
  overflow: hidden;
  top: -10px;
`;

const DesktopMenuContainer = styled.header`
  position: absolute;
  z-index: 100;
  width: 100%;
  height: 160px;
  @media print {
    display: none;
  }
`;

export const FixedBar = styled.div<{ isVisible: boolean }>`
  position: fixed;
  background-color: #fff;
  box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.1);
  top: -60px;
  width: 100%;
  height: 60px;
  z-index: 200;
  transition: 0.5s all;
  ${({ isVisible }) =>
    isVisible &&
    css`
      top: 0px;
    `};
`;

const LogoLink = styled(({ isPostPage, to, ...rest }) => (
  <Link to={to} {...rest} />
))<{ isPostPage: boolean }>`
  position: absolute;
  display: block;
  left: 0px;
  top: 53px;

  @media (max-width: 1260px) {
    left: 40px;
  }
  @media (max-width: 700px) {
    left: 20px;
    top: 40px;
  }
  ${Logo} {
    height: 56px;
    display: block;
    ${({ isPostPage }) =>
      isPostPage &&
      css`
        .st1 {
          fill: ${({ theme }) => theme.mainColor};
        }
      `};
  }
  &.fixed {
    top: 5px;
    left: 0px;
    @media (max-width: 1260px) {
      left: 20px;
    }
    ${Logo} {
      height: 50px;
      .st1 {
        fill: ${({ theme }) => theme.mainColor};
      }
    }
  }
`;

const RightBarItems = styled.div`
  position: absolute;
  right: 0;
  top: 65px;
  margin: 0;
  display: flex;
  align-items: baseline;
  padding-right: 20px;
  &.fixed {
    top: 10px;
    color: #333;
    @media (max-width: 850px) {
      top: 18px;
    }
  }
  @media (max-width: 850px) {
    right: 20px;
  }
`;

const List = styled.ul`
  list-style: none;
  @media (max-width: 1260px) {
    right: 40px;
  }
  @media (max-width: 850px) {
    display: none;
  }
`;

const NavLink = styled(({ isPostPage, to, ...rest }) => (
  <Link to={to} {...rest} />
))<{ isPostPage: boolean }>`
  display: block;
  margin: 0 0px 0 10px;
  padding: 12px 10px;
  letter-spacing: 0.1em;
  color: ${({ theme }) => theme.navigationColor};
  text-decoration: none;
  position: relative;
  transition: all 300ms;
  font-weight: 400;
  font-size: 14px;
  text-transform: uppercase;
  &.fixed {
    color: #333;
  }
  ${({ isPostPage }) =>
    isPostPage &&
    css`
      color: #333;
    `};
  &::before {
    background: ${({ theme }) => theme.navigationColor};
    ${({ isPostPage }) =>
      isPostPage &&
      css`
        background: #333;
      `};
    opacity: 0;
    bottom: -1px;
    content: "";
    height: 2px;
    left: 50%;
    position: absolute;
    width: 0%;
    transition: all 300ms;
    transform: translateX(-50%) translateY(0);
  }
  &:hover {
    &::before {
      opacity: 1;
      width: 100%;
    }
  }
`;
const ListItem = styled.li`
  display: inline-block;
`;

export default withTheme(Header);
