import FbIcon from "components/FbIcon";
import Icon from "components/Icon";
import { Email, Phone } from "components/UI";
import Wrapper from "components/Wrapper";
import React from "react";
import { Helmet } from "react-helmet";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import InstagramIcon from "./InstagramIcon";
import LinkedinIcon from "./LinkedinIcon";

export default class Footer extends React.Component<{}> {
  render() {
    return (
      <Container>
        <Wrapper>
          <Flexbox>
            <Icon />
            <Socials>
              <a
                href="https://www.instagram.com/commercialisti_dsg/"
                rel="noopener"
                target="_blank"
              >
                <InstagramIcon />
              </a>
              <a
                href="https://www.facebook.com/StudioDSG"
                rel="noopener"
                target="_blank"
              >
                <FbIcon />
              </a>
              <a
                href="https://www.linkedin.com/company/studiodsg"
                rel="noopener"
                target="_blank"
              >
                <LinkedinIcon />
              </a>
            </Socials>
            <Contacts>
              <Phone href="tel:0445-360277">0445 360277</Phone>
              <span> - </span>
              <Email href="mailto:info@studiodsg.it" target="_top">
                info@studiodsg.it
              </Email>
            </Contacts>
          </Flexbox>
          <BottomLine>
            <Copyright>
              <FormattedMessage
                id="footer.copyright"
                defaultMessage="© 2019 DSG Srl. All rights reserved. "
                values={{ year: new Date().getFullYear() }}
              />
            </Copyright>
            <CompanyInfo>
              <FormattedMessage
                id="footer.companyInfo"
                defaultMessage="Viale Europa, 29 - 36016 Thiene (VI) • P.IVA/C.F. 04146660248"
              />
            </CompanyInfo>
            <LegalInfo>
              <a
                href="https://www.iubenda.com/privacy-policy/63788227/cookie-policy"
                className="iubenda-nostyle no-brand iubenda-embed"
                title="Cookie Policy "
              >
                Cookie Policy
              </a>
              <a
                href="https://www.iubenda.com/privacy-policy/63788227"
                className="iubenda-nostyle no-brand iubenda-embed"
                title="Privacy Policy "
              >
                Privacy Policy
              </a>
              <Helmet>
                <script src="/assets/iubenda.js" />
                <script
                  type="text/javascript"
                  src="//cdn.iubenda.com/cookie_solution/safemode/iubenda_cs.js"
                  charSet="UTF-8"
                  async
                />
              </Helmet>
            </LegalInfo>
          </BottomLine>
        </Wrapper>
      </Container>
    );
  }
}

const Container = styled.footer`
  background-color: ${({ theme }) => theme.mainColor};
  position: relative;
  overflow: hidden;
  padding-top: 100px;
  padding-bottom: 100px;
  color: ${({ theme }) => theme.textColorLight};
  @media print {
    display: none;
  }
`;

const Socials = styled.div`
  display: flex;
  a {
    margin: 10px;
  }
`;

const Copyright = styled.p`
  text-align: center;
  padding-bottom: 30px;
`;

const CompanyInfo = styled.p`
  text-align: center;
`;

const Contacts = styled.div`
  padding-top: 40px;
  box-sizing: border-box;
  @media (max-width: 850px) {
    padding-left: 0;
  }
  ${Email} {
    color: ${({ theme }) => theme.textColorLight};
    font-size: 14px;
    font-weight: 400;
    margin: 0;
    padding: 0;
    &::before {
      background: ${({ theme }) => theme.textColorLight};
    }
  }
  ${Phone} {
    color: ${({ theme }) => theme.textColorLight};
    font-size: 14px;
    font-weight: 400;
    margin: 0;
    padding: 0;
    &::before {
      background: ${({ theme }) => theme.textColorLight};
    }
  }
`;

const Flexbox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${Icon} {
    fill: ${props => props.theme.colors.white};
    margin-bottom: 40px;
  }
  ${FbIcon} {
    padding: 0;
    margin: 0;
    fill: ${props => props.theme.colors.white};
  }
`;

const BottomLine = styled.div`
  padding-top: 50px;
  font-size: 13px;
  line-height: 1.4em;
  .copyright {
    padding-bottom: 15px;
  }
`;

const LegalInfo = styled.div`
  padding-top: 50px;
  font-size: 13px;
  line-height: 1.4em;
  text-align: center;
  a {
    color: #fff;
    text-align: center;
    padding: 5px;
  }
`;
