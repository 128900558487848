import React, { SVGProps } from "react";
import styled from "styled-components";

class Icon extends React.PureComponent<SVGProps<SVGSVGElement>> {
  render() {
    return (
      <svg
        x="0px"
        y="0px"
        width="200px"
        height="200px"
        viewBox="0 0 200 200"
        className={this.props.className}
      >
        <g>
          <path
            d="M23.25,16.33h1.95c0.21,2.04,2.07,3.99,4.97,3.99c3.3,0,4.94-2.1,4.94-4.05c0-2.25-2.01-3.33-4.05-3.84
			l-2.79-0.66c-4.26-1.02-4.88-3.51-4.88-5.15c0-3.42,3.15-5.63,6.56-5.63c3.51,0,6.29,2.16,6.41,5.6H34.4
			c-0.12-2.28-2.01-3.75-4.5-3.75c-2.34,0-4.53,1.47-4.53,3.78c0,0.96,0.3,2.61,3.39,3.33l2.79,0.66c3.27,0.81,5.54,2.58,5.54,5.57
			c0,2.91-2.4,5.99-6.92,5.99C26.13,22.18,23.4,19.33,23.25,16.33z"
          />
          <path d="M45.16,9.53h-3.21v12.35h-1.89V9.53h-2.55V7.88h2.55V2.31h1.89v5.57h3.21V9.53z" />
          <path
            d="M58.65,7.88v14h-1.92v-2.46c-0.84,1.98-3.12,2.76-4.85,2.76c-3.39,0-5.69-2.28-5.66-6.14V7.88h1.92v8.06
			c0,2.7,1.68,4.35,3.96,4.35c2.22,0,4.62-1.26,4.65-4.65V7.88H58.65z"
          />
          <path
            d="M75.34,0v21.88h-1.92v-2.73c-1.05,1.92-3.24,3.03-5.57,3.03c-3.9,0-7.1-3.06-7.1-7.31
			c0-4.26,3.18-7.28,7.1-7.28c2.37,0,4.53,1.11,5.57,3V0H75.34z M73.42,14.92c0-3.39-2.64-5.51-5.42-5.51
			c-2.94,0-5.33,2.34-5.33,5.42c0,3.12,2.4,5.51,5.33,5.51C70.91,20.35,73.42,18.16,73.42,14.92z"
          />
          <path
            d="M77.89,2.73c0-0.81,0.69-1.38,1.47-1.38c0.84,0,1.5,0.57,1.5,1.38c0,0.78-0.66,1.35-1.5,1.35
			C78.58,4.08,77.89,3.51,77.89,2.73z M78.4,7.88h1.92v14H78.4V7.88z"
          />
          <path
            d="M82.41,14.83c0-4.44,3.33-7.25,7.31-7.25s7.34,2.82,7.34,7.25c0,4.44-3.36,7.34-7.34,7.34
			S82.41,19.27,82.41,14.83z M95.15,14.83c0-3.3-2.49-5.42-5.42-5.42c-2.97,0-5.39,2.13-5.39,5.42c0,3.33,2.43,5.51,5.39,5.51
			C92.66,20.35,95.15,18.16,95.15,14.83z"
          />
        </g>
        <g>
          <path
            d="M109.76,83.15h11.71c0.23,6.78,6.54,13.32,16.42,13.32c9.42,0,14.93-5.74,14.93-12.29
		c0-7-6.32-10.33-12.75-12.06l-10.11-2.53c-16.88-4.25-19.64-14.24-19.64-20.79c0-13.89,12.29-22.74,26.3-22.74
		c14.24,0,25.38,8.96,25.38,23.31H150.3c0-7.47-5.86-12.52-14.01-12.52c-7.46,0-14.12,4.48-14.12,11.71c0,2.53,0.8,7.81,10.79,10.34
		l9.99,2.53c13.32,3.33,21.71,10.56,21.71,22.16c0,13.21-10.8,23.66-26.76,23.66C121.02,107.27,109.76,95.9,109.76,83.15z"
          />
          <path
            d="M176.64,145.28L176.64,145.28l-31.65,0v9.7h19.47c-0.04,0.38-0.1,0.76-0.17,1.08
		c-1.43,9.24-9.46,15.21-20.45,15.21c-13.07,0-23.7-11.16-23.7-24.88c0-14.01,10.41-24.98,23.7-24.98c5.82,0,11.64,2.32,15.99,6.36
		l0.35,0.33l7.84-7.19l-0.39-0.38c-6.23-6.13-14.68-9.5-23.79-9.5c-19.55,0-34.87,15.53-34.87,35.37
		c0,19.78,15.32,35.27,34.87,35.27c10.71,0,18.99-3.27,24.55-9.5v19.48H31.62V34.51h54.09v27.26c-3.46-5.91-10.71-8.81-18.07-8.81
		c-14.05,0-25.76,10.48-25.76,27.1c0,16.5,11.71,27.21,25.87,27.21c7.02,0,14.5-3.01,17.95-8.92v7.81h10.93V34.51v-8.43v-0.01H23.25
		V200h153.49v-54.72H176.64z M68.98,96.9c-8.92,0-16.17-7.25-16.17-16.95c0-9.81,7.25-16.62,16.17-16.62
		c8.03,0,16.62,6.13,16.62,16.62C85.6,90.43,77.79,96.9,68.98,96.9z"
          />
          <path
            d="M101.99,175.64l-12.03-10.71c0.81-1.53,4.11-8.61,4.11-21.98v-0.43h-0.43h-4.9h-0.43v0.43
		c0,7.33-0.79,11.99-1.45,14.61c-0.47,1.85-0.94,2.96-1.21,3.52l-18.13-16.2c9.65-8.01,13.3-11.76,13.3-18.53
		c0-4.05-1.65-7.83-4.66-10.66c-3.22-3.03-7.6-4.64-12.66-4.64c-5.06,0-9.44,1.6-12.66,4.64c-3,2.83-4.66,6.62-4.66,10.66
		c0,6.98,4.94,11.47,12.43,18.21l-0.18,0.14c-11.37,8.76-16.57,12.76-16.57,20.42c0,4.4,2.03,8.46,5.73,11.42
		c3.96,3.17,9.46,4.84,15.91,4.84c9.41,0,18.73-5.78,23.28-11.6l11.46,10.25l0.33,0.29l0.28-0.34l3.17-3.74l0.27-0.32L101.99,175.64
		z M63.53,141.24c-0.75-0.67-1.57-1.36-2.44-2.08c-4.2-3.5-8.96-7.46-8.96-12.81c0-5.54,4.78-9.55,11.38-9.55
		c6.59,0,11.38,4.02,11.38,9.55C74.89,132.14,71.33,135.04,63.53,141.24z M47.91,164.16c0-4.57,5.19-8.55,11.75-13.58
		c0.99-0.76,2.02-1.55,3.06-2.36l19.88,17.83c-3.72,4.83-10.98,9.59-19.1,9.59C53.75,175.63,47.91,171.34,47.91,164.16z"
          />
        </g>
      </svg>
    );
  }
}

export default styled(Icon)`
  height: 80px;
`;
