import React from "react";
import { Link as GatsbyLink, GatsbyLinkProps } from "gatsby";
import { addTrailingSlash } from "utils/url";

export const Link = ({
  to,
  children,
  ...otherProps
}: Omit<GatsbyLinkProps<{}>, "ref">) => {
  const toWithSlash = addTrailingSlash(to || "");
  return (
    <GatsbyLink to={toWithSlash} {...otherProps}>
      {children}
    </GatsbyLink>
  );
};
